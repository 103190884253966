@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;



/* privacy_policy
------------------------------------------------------- */
#privacy_policy{
  .wrap{
	  
    .content{
		text-align: center;
		width: 100%;
		margin: 0 auto;
		padding: 60px 0 100px 0;
		
      h2{
        @include font-size(26);
        font-family: adobe-garamond-pro, serif;
        margin: 100px auto;
      }
		
		.privacy_all{
			text-align: center;
			width: 90%;
			max-width: 700px;
			margin: 0 auto;
			padding: 0;
      
      p{
        text-align: left;
        margin-bottom: 60px;
      }
      p.txt2{
        text-align: left;
        margin-bottom: 20px;
      }
      p.day{
        text-align:right;
        margin-bottom: 60px;
      }
      h3{
        text-align: left;
        @include font-size(18);
        margin-bottom: 20px;
      }
      ul{
        text-align: left;
        margin: 0 0 50px 40px;
        padding: 0;
        
        li{
          list-style: disc;
          list-style-position: outside;
        }
      }
			
			
			
			}
		}
	}
}
  

/* privacy_policy-smp
------------------------------------------------------- */
@media only screen and (max-width:767px){
#privacy_policy{
  .wrap{
	  
    .content{
		text-align: center;
		@include font-size(12);
		width: 100%;
		margin: 0 auto;
		padding: 30px 0 0 0;
		
		h2{
        @include font-size(18);
        font-family: adobe-garamond-pro, serif;
        margin: 0 auto 30px auto;
		}
	  
		.privacy_all{
      margin-bottom: 50px;
      
      p{
        margin-bottom: 30px;
      }
      p.txt2{
        margin-bottom: 10px;
      }
      h3{
        text-align: left;
        @include font-size(16);
        margin-bottom: 10px;
      }
      ul{
        margin: 0 0 30px 20px;
      }
    }
      
    }
	  
    }
  }	
}

/* privacy_policy-tablet
------------------------------------------------------- */
@media screen and (min-width:481px) and ( max-width:1024px) {
#privacy_policy{
  .wrap{

    .content{
      padding: 100px 0 0 0;
    }
	  
	  
	}
  }	
}
