@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

/* ---------------------------
	File Name:common.scss
	Create:2018.3.12
	Author:Satsuki Yonekura
------------------------------ */

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;


// reset
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// 画像のみ右クリック禁止
// img {
//   pointer-events: none; 
// }

/* =============================================
  common
=============================================== */
.smp{
	display: none !important;
}
html {
	width: 100%;
	height: 100%;
	background-color: transparent;
	background-image: none;
	-webkit-text-size-adjust: 100%;
	font-size: 13px;
}
body{
	margin: 0;
	padding: 0;
	color: $textColor;
	font: 13px/1.7em 'Helvetica Neue','Helvetica','Arial','メイリオ',meiryo,'ヒラギノ角ゴ pro w3','hiragino kaku gothic pro',sans-serif;
	font-weight: 400;
	font-feature-settings: "palt";
	letter-spacing: 0.03em;
	animation: fadeInall 2s ease 0s 1 normal;
}
@keyframes fadeInall {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
img{
	// max-width: 100%;
	vertical-align: bottom;
}
strong{
	font-weight: bold;
}
sup{
	vertical-align: super !important;
}
a{
	display: block;
	color: $textColor;
	text-decoration: none;
	transition: all 0.5s ease;
	
	img{
		transition: all 0.5s ease;
	}
	&:hover{
		color: $pointPink;
		text-decoration: none;
		// opacity: .6;

		img{
			// transform: scale(1.05);
		}
	}
}

// clearfix
.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
// 明朝体
.sans-serif{
	font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
}

.wrap{
	width: 100%;
	background: #fff;
	overflow: hidden;
}
.content{
  min-height: 815px;
}

.pagetop {
  display: none;
}


header{
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	height: 60px;
	background: rgba(255,255,255,.8);

	.head-inner{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 95%;
		height: 100%;
		margin: 0 auto;

		h1{
			width: 30px;
		}
		.btn-menu{
			display: none;	
		}

		nav{
			@include font-size(14);
			
			ul{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 540px;

				li{
				  font-family: adobe-garamond-pro, serif;
				}
				li.cr{
				  color: $pointPink;
					a{
						color: $pointPink;
					}
				}
			}
		}
	}
}


footer{
	position: relative;
	width: 100%;
	padding: 25px 0;
	background: rgba(192,192,192,1);
	
	h1{
		width: 50px;
		margin: 0 auto;
    
    svg{
      width: 100%;
      max-height: 60px;
    }
	}
	.copyright{
		margin-top: 10px;
		@include font-size(10);
		text-align: center;
		color: #fff;
	}
	.privacy-link{
		position: absolute;
		right: 40px;
		bottom: 25px;
		padding-right: 20px;

		a{
			color: #fff;
		}
		&::before{
			position: absolute;
			right: 0;
			bottom: 8px;
			width: 15px;
			height: 5px;
			content: "";
			border-bottom: 1px solid #fff;
		}
		&::after{
			position: absolute;
			right: 0;
			bottom: 2px;
			width: 15px;
			height: 9px;
			content: "";
			border-right: 1px solid #fff;
			transform: rotate(-45deg);
		}
	}
}

.btn{
width: 200px;
margin: 0 auto;
background: #fff;
border: 1px solid $textColor;
transition: all 0.5s ease;

	a{
	  display: block;
	  width: 100%;
	  height: 100%;
	  padding: 10px 0;
	  box-sizing: border-box;
	  font-style: normal;
	  font-weight: 400;
	  text-align: center;
			transition: all 0.5s ease;
	}
	&:hover{
	  border: 1px solid $pointPink;
	  color: $pointPink;
	}
}



/* common-smp
------------------------------------------------------- */
@media only screen and (max-width:480px){
	// 画像の長押し禁止
	img{
		-webkit-touch-callout: none;
  	user-select:none;
	}
	.pc{
		display: none !important;
	}
	.smp{
		display: block !important;
	}

.pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  width: 45px;
  height: 45px;
  margin: 0 auto 10px;
  padding-top: 19px;
	border: 1px solid $textColor;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}
.pagetop span {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  border-top: 1px solid $textColor;
  border-right: 1px solid $textColor;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
	
	.wrap{
		background: transparent;
	}

	header{
    background: rgba(255,255,255,1);
		position: inherit;
		height: auto;

		.head-inner{
			position: relative;
			display: block;
			width: 100%;
			padding: 10px;
			box-sizing: border-box;

			h1{
				width: 25px;
			}
			
			.btn-menu{
				display: block;
				position: absolute;
				right: 0px;
				top: -2px;
				width: 40px;
				height: 40px;
				text-align: center;
				cursor: pointer;
				
				span{
					width: 30px;
					height: 1px;
					display: block;
					background: #999999;
					position: absolute;
					left: 50%;
					top: 50%;
					margin-left: -25px;
				}
				span.top{
					transform: translateY(0);
				}
				span.middle{
					transform: translateY(8px);
				}
				span.bottom{
					transform: translateY(16px);
				}
			}
			
			nav{
				display: block;
				position: relative;
				right: -70%;
				width: 100%;

				ul{
					display: block;
					width: 100%;
	
					li{
	
					}
				}
			}
			.menu {
				position: fixed;
				right: -70%;
				top: 0;
				z-index: 9999;
				width: 70%;
				height: 100vh;
				padding: 20px;
				background: rgba(0, 0, 0, 0.8);
				box-sizing: border-box;
			
				.close {
					position: relative;
					right: 10px;
					float: right;
					width: 30px;
					height: 30px;
					cursor: pointer;

					&::before {
						display: block;
						width: 30px;
						height: 30px;
						content: "";
						border-top: 1px solid #fff;
						transform: rotate(45deg);
					}
					&::after {
						position: absolute;
						right: -21px;
						top: 0;
						display: block;
						width: 30px;
						height: 30px;
						content: "";
						border-top: 1px solid #fff;
						transform: rotate(-45deg);
					}				
				}
				ul{
					li {
						padding: 20px 0;
						border-bottom: 1px solid #BBBBBB;
						@include font-size(18);
						color: #BBBBBB;
					}
					a {
						color: #BBBBBB;
					}
				}
			}
	
		}
	}

	footer{
		position: relative;
		width: 100%;
		padding: 10px 0;
		
		h1{
			width: 30px;
		}
		.copyright{
			margin: 0 auto;
			@include font-size(10);
		}

		.privacy-link{
			@include font-size(10);
			right: 10px;
			bottom: 10px;
		}	
	}
}

/* common-tablet
------------------------------------------------------- */
@media screen and (min-width:481px) and ( max-width:1024px) {
	// 画像の長押し禁止
	img{
		-webkit-touch-callout: none;
  	user-select:none;
	}

  
header{
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	height: 60px;
	background: rgba(255,255,255,.8);

	.head-inner{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 95%;
		height: 100%;
		margin: 0 auto;

		h1{
			width: 30px;
		}
		.btn-menu{
			display: none;	
		}

		nav{
			@include font-size(13);
			
			ul{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 410px;

				li{
				  font-family: adobe-garamond-pro, serif;
				}
				li.cr{
				  color: $pointPink;
					a{
						color: $pointPink;
					}
				}
			}
		}
	}
}
  
  
  
}

