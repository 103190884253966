@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;



/* about
------------------------------------------------------- */
#about{
  .wrap{
	  
    .content{
			text-align: center;
			width: 100%;
			margin: 0 auto;
			padding: 60px 0 100px 0;
		
      h2{
        @include font-size(26);
        font-family: adobe-garamond-pro, serif;
        margin: 100px auto;
      }
		
			.about_all{
				text-align: center;
				width: 90%;
				max-width: 700px;
				margin: 0 auto;
				padding: 0;
				
				h3{
					text-align: left;
					margin-bottom: 20px;
				}
				ul{
					text-align: center;
					@include font-size(13);
					margin: 0 auto 50px auto;
					padding: 0;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					
					li:nth-child(odd){
						text-align: left;
						width: 25%;
						background-color: #e9e9e9;
						margin: 0 0 5px 0;
						padding: 20px 20px;
						box-sizing: border-box;
					}
					li:nth-child(even){
						text-align: left;
						width: 75%;
						margin: 0 0 5px 0;
						padding: 20px 40px;
						box-sizing: border-box;
					}
				}
				
				.btn{
					width: 200px;
					line-height: 1.3;
					margin: 0 auto;
					background: #fff;
					border: 1px solid $textColor;
					transition: all 0.5s ease;

					a{
						display: block;
						width: 100%;
						height: 100%;
						padding: 10px 0;
						box-sizing: border-box;
						text-align: center;
							transition: all 0.5s ease;
					}
					&:hover{
						border: 1px solid $pointPink;
						color: $pointPink;
					}
				}
				
				h2.nopage{
					margin: 200px auto;
				}
			}
		}
	}
}
  

/* about-smp
------------------------------------------------------- */
@media only screen and (max-width:767px){
#about{
  .wrap{
	  
    .content{
		text-align: center;
		@include font-size(12);
		width: 100%;
      min-height: 686px;
		margin: 0 auto;
		padding: 30px 0 50px 0;
		
		h2{
        @include font-size(18);
        font-family: adobe-garamond-pro, serif;
        margin: 0 auto 30px auto;
		}
	  
		.about_all{
			width: 94%;
			margin: 0 auto 0 auto;
			
			h3{
				width: 90%;
				margin: 0 auto 20px auto;
			}
			
			ul{
				text-align: center;
				margin: 0 auto 20px auto;
				padding: 0;
				display: block;
				
				li:nth-child(odd){
          text-align: center;
					width: 94%;
          margin: 0 auto;
					padding: 10px 0;
				}
				li:nth-child(even){
					text-align: left;
					width: 100%;
					padding: 10px 20px;
				}
				span.smp{
					display: inline !important;
				}
			}
    .btn{
      width: 94%;
      line-height: 1.3;
      margin: 0 auto;
      background: #fff;
      border: 1px solid $textColor;
      transition: all 0.5s ease;

      a{
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        @include font-size(11);
        text-align: center;
        transition: all 0.5s ease;
      }
      &:hover{
        border: 1px solid $pointPink;
        color: $pointPink;
      }
    }
	  
      h2.nopage{
        margin: 200px auto 100px auto;
      }
      
		}
	  }
	  
	}
  }	
}

/* about-tablet
------------------------------------------------------- */
@media screen and (min-width:481px) and ( max-width:1024px) {
#about{
  .wrap{

    .content{
      padding: 100px 0 50px 0;
    }
	  
	  
	}
  }	
}

