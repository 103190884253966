@charset "UTF-8";
/* ---------------------------
	File Name:common.scss
	Create:2018.3.12
	Author:Satsuki Yonekura
------------------------------ */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* =============================================
  common
=============================================== */
.smp {
  display: none !important;
}

html {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: none;
  -webkit-text-size-adjust: 100%;
  font-size: 13px;
}

body {
  margin: 0;
  padding: 0;
  color: #5d5d5d;
  font: 13px/1.7em "Helvetica Neue", "Helvetica", "Arial", "メイリオ", meiryo, "ヒラギノ角ゴ pro w3", "hiragino kaku gothic pro", sans-serif;
  font-weight: 400;
  font-feature-settings: "palt";
  letter-spacing: 0.03em;
  animation: fadeInall 2s ease 0s 1 normal;
}

@keyframes fadeInall {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
img {
  vertical-align: bottom;
}

strong {
  font-weight: bold;
}

sup {
  vertical-align: super !important;
}

a {
  display: block;
  color: #5d5d5d;
  text-decoration: none;
  transition: all 0.5s ease;
}
a img {
  transition: all 0.5s ease;
}
a:hover {
  color: #c00f57;
  text-decoration: none;
}
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.sans-serif {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
}

.wrap {
  width: 100%;
  background: #fff;
  overflow: hidden;
}

.content {
  min-height: 815px;
}

.pagetop {
  display: none;
}

header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0.8);
}
header .head-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 100%;
  margin: 0 auto;
}
header .head-inner h1 {
  width: 30px;
}
header .head-inner .btn-menu {
  display: none;
}
header .head-inner nav {
  font-size: 14px;
  font-size: 1.0769230769rem;
}
header .head-inner nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 540px;
}
header .head-inner nav ul li {
  font-family: adobe-garamond-pro, serif;
}
header .head-inner nav ul li.cr {
  color: #c00f57;
}
header .head-inner nav ul li.cr a {
  color: #c00f57;
}

footer {
  position: relative;
  width: 100%;
  padding: 25px 0;
  background: rgb(192, 192, 192);
}
footer h1 {
  width: 50px;
  margin: 0 auto;
}
footer h1 svg {
  width: 100%;
  max-height: 60px;
}
footer .copyright {
  margin-top: 10px;
  font-size: 10px;
  font-size: 0.7692307692rem;
  text-align: center;
  color: #fff;
}
footer .privacy-link {
  position: absolute;
  right: 40px;
  bottom: 25px;
  padding-right: 20px;
}
footer .privacy-link a {
  color: #fff;
}
footer .privacy-link::before {
  position: absolute;
  right: 0;
  bottom: 8px;
  width: 15px;
  height: 5px;
  content: "";
  border-bottom: 1px solid #fff;
}
footer .privacy-link::after {
  position: absolute;
  right: 0;
  bottom: 2px;
  width: 15px;
  height: 9px;
  content: "";
  border-right: 1px solid #fff;
  transform: rotate(-45deg);
}

.btn {
  width: 200px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #5d5d5d;
  transition: all 0.5s ease;
}
.btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  transition: all 0.5s ease;
}
.btn:hover {
  border: 1px solid #c00f57;
  color: #c00f57;
}

/* common-smp
------------------------------------------------------- */
@media only screen and (max-width: 480px) {
  img {
    -webkit-touch-callout: none;
    user-select: none;
  }
  .pc {
    display: none !important;
  }
  .smp {
    display: block !important;
  }
  .pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    width: 45px;
    height: 45px;
    margin: 0 auto 10px;
    padding-top: 19px;
    border: 1px solid #5d5d5d;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
  }
  .pagetop span {
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    border-top: 1px solid #5d5d5d;
    border-right: 1px solid #5d5d5d;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .wrap {
    background: transparent;
  }
  header {
    background: rgb(255, 255, 255);
    position: inherit;
    height: auto;
  }
  header .head-inner {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  header .head-inner h1 {
    width: 25px;
  }
  header .head-inner .btn-menu {
    display: block;
    position: absolute;
    right: 0px;
    top: -2px;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
  }
  header .head-inner .btn-menu span {
    width: 30px;
    height: 1px;
    display: block;
    background: #999999;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
  }
  header .head-inner .btn-menu span.top {
    transform: translateY(0);
  }
  header .head-inner .btn-menu span.middle {
    transform: translateY(8px);
  }
  header .head-inner .btn-menu span.bottom {
    transform: translateY(16px);
  }
  header .head-inner nav {
    display: block;
    position: relative;
    right: -70%;
    width: 100%;
  }
  header .head-inner nav ul {
    display: block;
    width: 100%;
  }
  header .head-inner .menu {
    position: fixed;
    right: -70%;
    top: 0;
    z-index: 9999;
    width: 70%;
    height: 100vh;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
  }
  header .head-inner .menu .close {
    position: relative;
    right: 10px;
    float: right;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  header .head-inner .menu .close::before {
    display: block;
    width: 30px;
    height: 30px;
    content: "";
    border-top: 1px solid #fff;
    transform: rotate(45deg);
  }
  header .head-inner .menu .close::after {
    position: absolute;
    right: -21px;
    top: 0;
    display: block;
    width: 30px;
    height: 30px;
    content: "";
    border-top: 1px solid #fff;
    transform: rotate(-45deg);
  }
  header .head-inner .menu ul li {
    padding: 20px 0;
    border-bottom: 1px solid #BBBBBB;
    font-size: 18px;
    font-size: 1.3846153846rem;
    color: #BBBBBB;
  }
  header .head-inner .menu ul a {
    color: #BBBBBB;
  }
  footer {
    position: relative;
    width: 100%;
    padding: 10px 0;
  }
  footer h1 {
    width: 30px;
  }
  footer .copyright {
    margin: 0 auto;
    font-size: 10px;
    font-size: 0.7692307692rem;
  }
  footer .privacy-link {
    font-size: 10px;
    font-size: 0.7692307692rem;
    right: 10px;
    bottom: 10px;
  }
}
/* common-tablet
------------------------------------------------------- */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  img {
    -webkit-touch-callout: none;
    user-select: none;
  }
  header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.8);
  }
  header .head-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 100%;
    margin: 0 auto;
  }
  header .head-inner h1 {
    width: 30px;
  }
  header .head-inner .btn-menu {
    display: none;
  }
  header .head-inner nav {
    font-size: 13px;
    font-size: 1rem;
  }
  header .head-inner nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 410px;
  }
  header .head-inner nav ul li {
    font-family: adobe-garamond-pro, serif;
  }
  header .head-inner nav ul li.cr {
    color: #c00f57;
  }
  header .head-inner nav ul li.cr a {
    color: #c00f57;
  }
}
/* top
------------------------------------------------------- */
#top .wrap .content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: url("../../img/bg.jpg") center top fixed;
  background-size: cover;
}
#top .wrap .content .main {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("../../img/bg_main.jpg") top left fixed;
  background-size: cover;
  box-sizing: border-box;
  text-align: center;
}
#top .wrap .content .main .logoArea {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
#top .wrap .content .main .logoArea h2 {
  font-size: 70px;
  font-size: 5.3846153846rem;
  color: #8b8b9c;
}
#top .wrap .content .main .logoArea .sub {
  margin-top: 30px;
  font-family: adobe-garamond-pro, serif;
  font-size: 20px;
  font-size: 1.5384615385rem;
  color: #c00f57;
}
#top .wrap .content .main .news {
  position: absolute;
  right: 0;
  bottom: 30px;
  width: auto;
  max-width: 94%;
  padding: 5px 20px;
  background: rgba(255, 255, 255, 0.85);
  box-sizing: border-box;
  text-align: left;
}
#top .wrap .content .main .topCap {
  position: absolute;
  right: 20px;
  bottom: 5px;
  padding: 0;
  font-size: 10px;
  font-size: 0.7692307692rem;
  text-align: right;
  color: #5d5d5d;
}
#top .wrap .content .bg {
  position: relative;
  width: 100%;
  height: 1000px;
  background: url("../../img/main01.jpg") no-repeat center bottom fixed;
  background-size: cover;
}
#top .wrap .content .bg .bgCap {
  position: absolute;
  right: 20px;
  bottom: 5px;
  padding: 0;
  font-size: 10px;
  font-size: 0.7692307692rem;
  text-align: right;
  color: #CCCCCC;
}
#top .wrap .content .bg .bgImg {
  display: none;
}
#top .wrap .content .concept {
  padding: 100px 0;
  text-align: center;
}
#top .wrap .content .concept h2 {
  margin-bottom: 20px;
  font-size: 20px;
  font-size: 1.5384615385rem;
  font-family: adobe-garamond-pro, serif;
}
#top .wrap .content .concept p {
  margin: 30px auto;
}
#top .wrap .content .concept p strong {
  display: block;
  margin-bottom: 40px;
  font-size: 18px;
  font-size: 1.3846153846rem;
}
#top .wrap .content .concept h3 {
  margin: 40px auto;
  font-size: 18px;
  font-size: 1.3846153846rem;
}
#top .wrap .content .concept .langChg {
  display: block;
  position: relative;
  width: 60px;
  margin: 0 auto 50px;
  padding-left: 20px;
  border: none;
  background: transparent;
  color: #5d5d5d;
  cursor: pointer;
  transition: all 0.5s ease;
}
#top .wrap .content .concept .langChg:hover {
  color: #c00f57;
}
#top .wrap .content .concept .langChg::before {
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 15px;
  height: 5px;
  content: "";
  border-bottom: 1px solid #343434;
}
#top .wrap .content .concept .langChg::after {
  position: absolute;
  left: -1px;
  bottom: 2px;
  width: 15px;
  height: 9px;
  content: "";
  border-right: 1px solid #343434;
  transform: rotate(-45deg);
}
#top .wrap .content .concept .toggleArea h3 {
  font-size: 19px;
  font-size: 1.4615384615rem;
  margin: 0 auto;
}
#top .wrap .content .concept .toggleArea p {
  font-size: 14px;
  font-size: 1.0769230769rem;
}
#top .wrap .content .concept .toggleArea p.last {
  margin: 30px auto 80px;
}
#top .wrap .content .concept .toggleArea .newline {
  display: block;
}
#top .wrap .content .concept .btn {
  margin: 0 auto;
}
#top .wrap .content .service {
  width: 100%;
  padding: 100px 0;
  background: #ffffff;
}
#top .wrap .content .service .service-wrap {
  display: flex;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 50px;
}
#top .wrap .content .service .service-wrap .service-box {
  width: calc((100% - 10px) / 3);
  padding: 0;
  text-align: center;
}
#top .wrap .content .service .service-wrap .service-box:last-child {
  margin-right: 0;
}
#top .wrap .content .service .service-wrap .service-box h3 {
  position: relative;
  margin-bottom: 25px;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  font-size: 2rem;
  line-height: 1.1;
}
#top .wrap .content .service .service-wrap .service-box h3::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  width: 20px;
  margin: 0 auto;
  border-top: 2px solid #c00f57;
  margin: 0 auto;
}
#top .wrap .content .service .service-wrap .service-box p {
  margin-top: 36px;
}
#top .wrap .content .gallery {
  padding: 0 0 100px 0;
  background: transparent;
  background: url("../../img/bg.jpg") no-repeat center bottom;
  background-size: cover;
}
#top .wrap .content .gallery .gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
  padding: 5px 5px 0 5px;
  background-color: #FFFFFF;
  box-sizing: border-box;
}
#top .wrap .content .gallery .gallery-wrap p {
  width: calc((100% - 20px) / 3);
  margin: 0 5px 5px 0;
}
#top .wrap .content .gallery .gallery-wrap p:nth-child(3n) {
  margin-right: 0;
}
#top .wrap .content .btn {
  width: 200px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #5d5d5d;
  transition: all 0.5s ease;
}
#top .wrap .content .btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 16px;
  font-size: 1.2307692308rem;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  transition: all 0.5s ease;
}
#top .wrap .content .btn:hover {
  border: 1px solid #c00f57;
  color: #c00f57;
}
#top .imgAll {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
#top .full-image figure img {
  width: auto;
  max-width: 100%;
  max-height: 80vh;
  margin-bottom: 10px;
}
#top .full-image figure figcaption .imgCap {
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-align: right;
  font-size: 10px;
  font-size: 0.7692307692rem;
  margin-bottom: 10px;
}
#top .full-image figure figcaption .btn {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999999;
  width: 100px;
  margin: 0 auto;
  background: transparent;
  border: none;
  transition: all 0.5s ease;
}
#top .full-image figure figcaption .btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px 0;
  box-sizing: border-box;
  font-size: 12px;
  font-size: 0.9230769231rem;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #AAAAAA;
  transition: all 0.5s ease;
}
#top .full-image figure figcaption .btn a:hover {
  color: #c00f57;
}
#top .full-image figure figcaption .btn a:active {
  color: #c00f57;
}
#top .full-image figure figcaption .btn a::before {
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 15px;
  height: 5px;
  content: "";
  border-bottom: 1px solid #AAAAAA;
}
#top .full-image figure figcaption .btn a::after {
  position: absolute;
  left: -1px;
  bottom: 10px;
  width: 15px;
  height: 9px;
  content: "";
  border-right: 1px solid #AAAAAA;
  transform: rotate(-45deg);
}
#top #close-button {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 99999;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border: 0;
  background: url("../../img/close.png") no-repeat top center;
  outline: 0;
  cursor: pointer;
  text-indent: -9999px;
  overflow: visible;
}
#top #close-button svg {
  display: none;
}
#top #close-button:hover {
  background-position: bottom center;
}
#top #previous-button {
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../img/ico_previous.png") no-repeat center left;
  background-size: 40px 60px;
  width: 15%;
  height: 94%;
  outline: none;
  text-indent: -9999px;
}
#top #previous-button svg {
  display: none;
}
#top #next-button {
  position: absolute;
  top: 0;
  right: 0;
  background: url("../../img/ico_next.png") no-repeat center right;
  background-size: 40px 60px;
  width: 15%;
  height: 94%;
  outline: none;
  text-indent: -9999px;
}
#top #next-button svg {
  display: none;
}
#top .imgArea {
  text-align: center;
  width: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #AAAAAA;
}
#top .imgArea img {
  width: auto;
  max-width: 100%;
  max-height: 80vh;
  margin-bottom: 10px;
}
#top .imgArea .imgCap {
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-align: right;
  font-size: 10px;
  font-size: 0.7692307692rem;
  margin-bottom: 10px;
}
#top .imgArea .btn {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999999;
  width: 100px;
  margin: 0 auto;
  background: transparent;
  border: none;
  transition: all 0.5s ease;
}
#top .imgArea .btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px 0;
  box-sizing: border-box;
  font-size: 12px;
  font-size: 0.9230769231rem;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #AAAAAA;
  transition: all 0.5s ease;
}
#top .imgArea .btn a:hover {
  color: #c00f57;
}
#top .imgArea .btn a:active {
  color: #c00f57;
}
#top .imgArea .btn a::before {
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 15px;
  height: 5px;
  content: "";
  border-bottom: 1px solid #AAAAAA;
}
#top .imgArea .btn a::after {
  position: absolute;
  left: -1px;
  bottom: 10px;
  width: 15px;
  height: 9px;
  content: "";
  border-right: 1px solid #AAAAAA;
  transform: rotate(-45deg);
}

/* top-smp
------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  #top .wrap .content {
    width: 100%;
    font-size: 12px;
    font-size: 0.9230769231rem;
    margin: 0 auto;
  }
  #top .wrap .content .main {
    position: relative;
    width: 100%;
    height: 67vh;
    background: url("../../img/bg_main.jpg") no-repeat left top;
    background-size: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  #top .wrap .content .main .logoArea {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  #top .wrap .content .main .logoArea h2 {
    font-size: 40px;
    font-size: 3.0769230769rem;
  }
  #top .wrap .content .main .logoArea .sub {
    margin-top: 10px;
    font-size: 10px;
    font-size: 0.7692307692rem;
  }
  #top .wrap .content .main .news {
    position: absolute;
    right: 0;
    bottom: 30px;
    width: auto;
    padding: 5px 15px;
    background: rgba(255, 255, 255, 0.85);
    box-sizing: border-box;
    text-align: left;
  }
  #top .wrap .content .main .topCap {
    position: absolute;
    right: 20px;
    bottom: 5px;
    padding: 0;
    font-size: 10px;
    font-size: 0.7692307692rem;
    text-align: right;
    color: #5d5d5d;
  }
  #top .wrap .content .bg {
    width: 100%;
    height: auto;
  }
  #top .wrap .content .bg .bgImg {
    display: block;
  }
  #top .wrap .content .concept {
    position: relative;
    padding: 80px 0 80px 0;
    background-image: none;
    background-color: rgb(255, 255, 255);
  }
  #top .wrap .content .concept h2 {
    margin-bottom: 70px;
  }
  #top .wrap .content .concept p {
    width: 90%;
    margin: 0 auto 20px auto;
    text-align: left;
  }
  #top .wrap .content .concept p strong {
    text-align: center;
  }
  #top .wrap .content .concept .langChg {
    width: 57px;
    margin: 0 auto 50px;
  }
  #top .wrap .content .concept .langChg:hover {
    color: #5d5d5d;
  }
  #top .wrap .content .concept .langChg:active {
    color: #c00f57;
  }
  #top .wrap .content .concept span.smp {
    display: inline-block !important;
  }
  #top .wrap .content .concept .toggleArea h3 {
    text-align: left;
    width: 90%;
    margin: 0 auto 20px auto;
  }
  #top .wrap .content .concept .toggleArea p.last {
    margin-bottom: 50px;
  }
  #top .wrap .content .concept .toggleArea .newline {
    display: none;
  }
  #top .wrap .content .concept .btn {
    width: 90%;
  }
  #top .wrap .content .service {
    padding: 0 0 80px 0;
  }
  #top .wrap .content .service .service-wrap {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
  #top .wrap .content .service .service-wrap .service-box {
    width: 90%;
    margin: 0 auto 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid rgba(243, 243, 243, 0.9529411765);
  }
  #top .wrap .content .service .service-wrap .service-box:last-child {
    margin-right: auto;
  }
  #top .wrap .content .service .service-wrap .service-box h3 {
    margin-bottom: 25px;
    font-family: adobe-garamond-pro, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    font-size: 2rem;
  }
  #top .wrap .content .service .btn {
    width: 90%;
  }
  #top .wrap .content .gallery {
    background: url("../../img/bg.jpg") no-repeat left bottom scroll;
    background-size: 100% auto;
    padding-bottom: 80px;
  }
  #top .wrap .content .gallery .gallery-wrap {
    margin-bottom: 50px;
  }
  #top .wrap .content .gallery .gallery-wrap p {
    width: 100%;
    margin: 0 0 5px;
  }
  #top .wrap .content .gallery .btn {
    width: 93%;
  }
  #top .imgArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh; /* 変数をサポートしていないブラウザのフォールバック */
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
  }
  #top .imgArea img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 60vh;
    margin-bottom: 0;
  }
  #top .imgArea .imgCap {
    bottom: 40px;
  }
  #top .imgArea .btn {
    right: 0;
    bottom: 20px;
    z-index: 999999;
  }
  #top #cboxNext {
    right: -20px;
  }
  #top #cboxPrevious {
    left: -20px;
  }
  #top .full-image figure img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 60vh;
    margin-bottom: 0;
  }
  #top .full-image figure figcaption .imgCap {
    bottom: 40px;
  }
  #top .full-image figure figcaption .btn {
    right: 0;
    bottom: 20px;
    z-index: 999999;
  }
}
/* top-tablet
------------------------------------------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #top .wrap .content {
    background: transparent;
  }
  #top .wrap .content .main {
    position: relative;
    width: 100%;
    height: 75vh;
    background: url("../../img/bg_main.jpg") top left fixed;
    background-size: 100% auto;
    box-sizing: border-box;
    text-align: center;
  }
  #top .wrap .content .bg {
    position: relative;
    width: 100%;
    height: 70vh;
    background: url("../../img/main01.jpg") no-repeat center bottom scroll;
    background-size: cover;
  }
  #top .wrap .content .bg .bgImg {
    display: none;
  }
  #top .wrap .content .concept {
    padding: 70px 0 80px 0;
  }
  #top .wrap .content .concept h2 {
    margin-bottom: 65px;
  }
  #top .wrap .content .concept .langChg:hover {
    color: #5d5d5d;
  }
  #top .wrap .content .concept .langChg:active {
    color: #c00f57;
  }
  #top .wrap .content .concept .toggleArea p.last {
    margin-bottom: 50px;
  }
  #top .wrap .content .service {
    padding: 0 0 80px 0;
  }
  #top .wrap .content .service .service-wrap {
    width: 94%;
  }
  #top .wrap .content .service .service-wrap .service-box {
    margin: 0 auto;
    padding: 0 0;
  }
  #top .wrap .content .gallery {
    background-size: 100% auto;
    padding-bottom: 80px;
  }
  #top .imgArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 100%;
  }
  #top .imgArea img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  #top .imgArea .imgCap {
    bottom: 40px;
  }
  #top .imgArea .btn {
    right: 0;
    bottom: 20px;
    z-index: 999999;
  }
  #top #cboxNext {
    right: -20px;
    height: 92%;
  }
  #top #cboxPrevious {
    left: -20px;
    height: 92%;
  }
  #top .full-image figure img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  #top .full-image figure figcaption .imgCap {
    bottom: 40px;
  }
  #top .full-image figure figcaption .btn {
    right: 0;
    bottom: 20px;
    z-index: 999999;
  }
}
@media screen and (min-width: 961px) and (max-width: 1024px) {
  #top #previous-button,
  #top #next-button {
    display: block !important;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* gallery
------------------------------------------------------- */
#gallery .pagetop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  width: 45px;
  height: 45px;
  margin: 0 auto 10px;
  padding-top: 19px;
  border: 1px solid #5d5d5d;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}
#gallery .pagetop span {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  border-top: 1px solid #5d5d5d;
  border-right: 1px solid #5d5d5d;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#gallery .wrap .content {
  width: 100%;
  min-height: 990px;
  margin: 0 auto;
  padding: 60px 0 100px 0;
  text-align: center;
}
#gallery .wrap .content h2 {
  font-size: 26px;
  font-size: 2rem;
  font-family: adobe-garamond-pro, serif;
  margin: 100px auto;
}
#gallery .wrap .content .gallery_all {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
#gallery .wrap .content .gallery_all .categoryBtn {
  display: none;
}
#gallery .wrap .content .gallery_all a {
  display: inline;
  color: #5d5d5d;
  text-decoration: none;
  transition: all 0.5s ease;
}
#gallery .wrap .content .gallery_all a:hover {
  color: #c00f57;
  text-decoration: none;
}
#gallery .wrap .content .gallery_all .cr {
  color: #c00f57;
}
#gallery .wrap .content .gallery_all .left_column {
  position: relative;
  width: 25%;
  max-width: 260px;
  margin: 0;
  padding: 0;
  text-align: left;
}
#gallery .wrap .content .gallery_all .left_column .categorywrap {
  position: absolute;
  top: 0;
  left: 0;
}
#gallery .wrap .content .gallery_all .left_column h3 {
  margin: 0 auto 20px;
  font-size: 15px;
  font-size: 1.1538461538rem;
}
#gallery .wrap .content .gallery_all .left_column ul {
  text-align: left;
  margin: 0 0 20px;
}
#gallery .wrap .content .gallery_all .left_column ul li {
  position: relative;
  margin: 0 0 10px 30px;
  line-height: 1.5;
  font-size: 12px;
  font-size: 0.9230769231rem;
  animation: fadeIn 1s ease-out 0s forwards;
}
#gallery .wrap .content .gallery_all .left_column ul li::before {
  position: absolute;
  top: 0;
  left: -15px;
  content: "-　";
}
#gallery .wrap .content .gallery_all .left_column .chgShow {
  transition: all 0.5s ease;
  cursor: pointer;
}
#gallery .wrap .content .gallery_all .left_column .chgShow:hover {
  color: #c00f57;
  text-decoration: none;
}
#gallery .wrap .content .gallery_all .left_column .chgShow.cr a {
  color: #c00f57;
}
#gallery .wrap .content .gallery_all .left_column [data-category=private-resindece],
#gallery .wrap .content .gallery_all .left_column [data-category=speciality_made],
#gallery .wrap .content .gallery_all .left_column [data-category=styling],
#gallery .wrap .content .gallery_all .left_column .private-residence {
  display: none;
}
#gallery .wrap .content .gallery_all .right_column {
  width: 70%;
  max-width: 900px;
}
#gallery .wrap .content .gallery_all .right_column .g_entry {
  margin-bottom: 100px;
}
#gallery .wrap .content .gallery_all .right_column .g_entry h3 {
  margin-bottom: 10px;
  text-align: left;
  font-size: 22px;
  font-size: 1.6923076923rem;
  opacity: 0;
  animation: fadeIn 5s ease-out 2s forwards;
}
#gallery .wrap .content .gallery_all .right_column .g_entry p.category {
  margin-bottom: 10px;
  text-align: left;
  font-size: 10px;
  font-size: 0.7692307692rem;
  opacity: 0;
  animation: fadeIn 5s ease-out 2s forwards;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  /* Edge */
  /* IE11 */
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul li {
  flex-grow: 0;
  width: calc((100% - 10px) / 3);
  margin: 0 5px 5px 0;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul li figure {
  display: block;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul li:nth-child(3n) {
  margin-right: 0;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul li img {
  width: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
  -ms-interpolation-mode: bicubic; /*IE対策*/
  opacity: 0;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul li .loaded {
  animation: fadeIn 5s ease-out 0s forwards;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul _:-ms-lang(x)::backdrop, #gallery .wrap .content .gallery_all .right_column .g_entry ul li {
  width: 32.5%;
}
#gallery .wrap .content .gallery_all .right_column .g_entry ul *::-ms-backdrop, #gallery .wrap .content .gallery_all .right_column .g_entry ul li {
  width: 32.5%;
}
#gallery .wrap .content .gallery_all .right_column .g_entry .caption {
  text-align: right;
  line-height: 1.5;
  font-size: 10px;
  font-size: 0.7692307692rem;
  transform: scale(0.8);
  transform-origin: right center;
  margin-right: 10px;
  opacity: 0;
  animation: fadeIn 5s ease-out 2s forwards;
}
#gallery .wrap .content .gallery_all .right_column .g_entry .description {
  opacity: 0;
  animation: fadeIn 5s ease-out 2s forwards;
}
#gallery .wrap .content .gallery_all .right_column .g_entry .description p {
  text-align: left;
  font-size: 13px;
  font-size: 1rem;
}
#gallery .wrap .content .gallery_all .right_column .showArea {
  display: none;
}
#gallery .wrap .content .gallery_all .right_column .showArea:first-child {
  display: block;
}
#gallery .wrap .content .gallery_category {
  display: flex;
  justify-content: space-between;
  width: 60%;
  max-width: 1300px;
  margin: 0 auto;
}
#gallery .wrap .content .gallery_category li {
  position: relative;
  width: calc((100% - 80px) / 2);
  max-width: 610px;
}
#gallery .wrap .content .gallery_category li h3 {
  font-size: 26px;
  font-size: 2rem;
  line-height: 1.1;
  font-family: adobe-garamond-pro, serif;
}
#gallery .wrap .content .gallery_category li h3::after {
  display: block;
  width: 20px;
  height: 2px;
  margin: 20px auto;
  content: "";
  background: #c00f57;
}
#gallery .wrap .content .gallery_category li .description {
  margin-bottom: 30px;
}
#gallery .wrap .content .gallery_category li a:hover .category_photo img {
  transform: scale(1.2);
}
#gallery .wrap .content .gallery_category li .category_photo {
  transition: all 0.5s ease;
  overflow: hidden;
}
#gallery .wrap .content .gallery_category li .btn_gallery {
  width: 50%;
  max-width: 250px;
  margin: 30px auto;
  border: 1px solid #000;
  font-size: 13px;
  font-size: 1rem;
  line-height: 1.3;
  font-family: adobe-garamond-pro, serif;
}
#gallery .wrap .content .gallery_category li .btn_gallery a {
  padding: 12px;
}
#gallery .imgAll {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94vh;
  box-sizing: border-box;
  overflow: hidden;
}
#gallery .imgArea {
  width: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  color: #AAAAAA;
}
#gallery .imgArea img {
  width: auto;
  max-height: 60vh;
  margin-bottom: 10px;
}
#gallery .imgArea .imgCap {
  font-size: 10px;
  font-size: 0.7692307692rem;
  margin-bottom: 20px;
  text-align: right;
}
#gallery #cboxLoadedContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}
#gallery #cboxLoadedContent .cboxPhoto {
  width: auto !important;
  max-width: 90%;
  height: auto !important;
  max-height: 80% !important;
  margin: auto !important;
}
#gallery #cboxTitle {
  float: none !important;
  right: 20px;
  left: auto;
  top: auto;
  bottom: 20px;
  font-size: 10px;
  font-size: 0.7692307692rem;
  text-align: right;
}
#gallery #cboxNext {
  right: 0;
}
#gallery #cboxPrevious {
  left: 0;
}
#gallery .full-image figure img {
  width: auto;
  max-width: 100%;
  max-height: 80vh;
  margin-bottom: 10px;
}
#gallery .full-image figure figcaption .imgCap {
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-align: right;
  font-size: 10px;
  font-size: 0.7692307692rem;
  margin-bottom: 10px;
}
#gallery .full-image figure figcaption .btn {
  display: none;
}
#gallery #close-button {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 99999;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border: 0;
  background: url("../../img/close.png") no-repeat top center;
  outline: 0;
  cursor: pointer;
  text-indent: -9999px;
  overflow: visible;
}
#gallery #close-button svg {
  display: none;
}
#gallery #close-button:hover {
  background-position: bottom center;
}
#gallery #previous-button {
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../img/ico_previous.png") no-repeat center left;
  background-size: 40px 60px;
  width: 15%;
  height: 100%;
  outline: none;
  text-indent: -9999px;
}
#gallery #previous-button svg {
  display: none;
}
#gallery #next-button {
  position: absolute;
  top: 0;
  right: 0;
  background: url("../../img/ico_next.png") no-repeat center right;
  background-size: 40px 60px;
  width: 15%;
  height: 100%;
  outline: none;
  text-indent: -9999px;
}
#gallery #next-button svg {
  display: none;
}

/* gallery-smp
------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  #gallery .pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    width: 45px;
    height: 45px;
    margin: 0 auto 10px;
    padding-top: 19px;
    border: 1px solid #5d5d5d;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
  }
  #gallery .pagetop span {
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    border-top: 1px solid #5d5d5d;
    border-right: 1px solid #5d5d5d;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #gallery .wrap .content {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 0 0;
  }
  #gallery .wrap .content h2 {
    font-size: 18px;
    font-size: 1.3846153846rem;
    font-family: adobe-garamond-pro, serif;
    margin: 0 auto 30px auto;
  }
  #gallery .wrap .content .gallery_all {
    width: 94%;
    display: block;
  }
  #gallery .wrap .content .gallery_all .left_column {
    position: static;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 30px auto;
    padding: 0 0 10px 0;
    text-align: center;
    border-bottom: 1px solid #EEEEEE;
  }
  #gallery .wrap .content .gallery_all .left_column .categorywrap {
    display: none;
    position: static;
  }
  #gallery .wrap .content .gallery_all .left_column h2 {
    font-family: adobe-garamond-pro, serif;
    margin: 0 auto 30px auto;
    font-size: 30px;
    font-size: 2.3076923077rem;
  }
  #gallery .wrap .content .gallery_all .left_column .categoryBtn {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 auto 20px auto;
    padding: 8px 0;
    border: 1px solid #EEEEEE;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  #gallery .wrap .content .gallery_all .left_column .categoryBtn span {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 auto;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
    transform: rotate(135deg);
  }
  #gallery .wrap .content .gallery_all .left_column h3 {
    text-align: left;
    margin: 0 0 10px;
  }
  #gallery .wrap .content .gallery_all .left_column ul {
    margin: 0 0 10px;
  }
  #gallery .wrap .content .gallery_all .left_column ul li {
    margin: 0 0 5px 30px;
    padding: 10px 0;
  }
  #gallery .wrap .content .gallery_all .left_column ul li::before {
    top: 10px;
  }
  #gallery .wrap .content .gallery_all .right_column {
    width: 100%;
    max-width: 900px;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #EEEEEE;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry h3 {
    margin-bottom: 5px;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry ul {
    margin-bottom: 5px;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry ul li {
    width: 49.5%;
    margin: 0 1% 1% 0;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry ul li:nth-child(3n) {
    margin-right: 1%;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry ul li:nth-child(2n) {
    margin-right: 0;
  }
  #gallery .wrap .content .gallery_all .right_column .g_entry .caption {
    margin-bottom: 5px;
  }
  #gallery .wrap .content .gallery_category {
    display: block;
    width: 90%;
    margin: 0 auto;
  }
  #gallery .wrap .content .gallery_category li {
    width: 100%;
  }
  #gallery .wrap .content .gallery_category li .btn_gallery {
    width: 90%;
  }
  #gallery #cboxWrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100vw !important;
    height: 85vh !important;
    overflow: hidden !important;
  }
  #gallery #cboxLoadedContent {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    max-height: 100vh;
  }
  #gallery #cboxLoadedContent .cboxPhoto {
    width: auto !important;
    max-width: 100%;
    height: auto !important;
    max-height: 90% !important;
    margin: auto !important;
  }
  #gallery #colorbox {
    position: relative;
    top: 0 !important;
  }
  #gallery #cboxContent {
    position: static;
  }
  #gallery #cboxTitle {
    bottom: 5px;
  }
  #gallery #cboxClose {
    top: 0;
    right: 5px;
  }
  #gallery #cboxNext {
    right: -20px;
  }
  #gallery #cboxPrevious {
    left: -20px;
  }
}
/* gallery-tablet
------------------------------------------------------- */
/* top-tablet
------------------------------------------------------- */
@media screen and (min-width: 768px) and (max-width: 960px) {
  #gallery .wrap .content .gallery_category li .btn_gallery {
    width: 90%;
  }
  #gallery #cboxNext {
    right: -20px;
  }
  #gallery #cboxPrevious {
    left: -20px;
  }
}
@media screen and (min-width: 961px) and (max-width: 1024px) {
  #gallery #previous-button,
  #gallery #next-button {
    display: block !important;
  }
}
/* about
------------------------------------------------------- */
#about .wrap .content {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 100px 0;
}
#about .wrap .content h2 {
  font-size: 26px;
  font-size: 2rem;
  font-family: adobe-garamond-pro, serif;
  margin: 100px auto;
}
#about .wrap .content .about_all {
  text-align: center;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0;
}
#about .wrap .content .about_all h3 {
  text-align: left;
  margin-bottom: 20px;
}
#about .wrap .content .about_all ul {
  text-align: center;
  font-size: 13px;
  font-size: 1rem;
  margin: 0 auto 50px auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#about .wrap .content .about_all ul li:nth-child(odd) {
  text-align: left;
  width: 25%;
  background-color: #e9e9e9;
  margin: 0 0 5px 0;
  padding: 20px 20px;
  box-sizing: border-box;
}
#about .wrap .content .about_all ul li:nth-child(even) {
  text-align: left;
  width: 75%;
  margin: 0 0 5px 0;
  padding: 20px 40px;
  box-sizing: border-box;
}
#about .wrap .content .about_all .btn {
  width: 200px;
  line-height: 1.3;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #5d5d5d;
  transition: all 0.5s ease;
}
#about .wrap .content .about_all .btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.5s ease;
}
#about .wrap .content .about_all .btn:hover {
  border: 1px solid #c00f57;
  color: #c00f57;
}
#about .wrap .content .about_all h2.nopage {
  margin: 200px auto;
}

/* about-smp
------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  #about .wrap .content {
    text-align: center;
    font-size: 12px;
    font-size: 0.9230769231rem;
    width: 100%;
    min-height: 686px;
    margin: 0 auto;
    padding: 30px 0 50px 0;
  }
  #about .wrap .content h2 {
    font-size: 18px;
    font-size: 1.3846153846rem;
    font-family: adobe-garamond-pro, serif;
    margin: 0 auto 30px auto;
  }
  #about .wrap .content .about_all {
    width: 94%;
    margin: 0 auto 0 auto;
  }
  #about .wrap .content .about_all h3 {
    width: 90%;
    margin: 0 auto 20px auto;
  }
  #about .wrap .content .about_all ul {
    text-align: center;
    margin: 0 auto 20px auto;
    padding: 0;
    display: block;
  }
  #about .wrap .content .about_all ul li:nth-child(odd) {
    text-align: center;
    width: 94%;
    margin: 0 auto;
    padding: 10px 0;
  }
  #about .wrap .content .about_all ul li:nth-child(even) {
    text-align: left;
    width: 100%;
    padding: 10px 20px;
  }
  #about .wrap .content .about_all ul span.smp {
    display: inline !important;
  }
  #about .wrap .content .about_all .btn {
    width: 94%;
    line-height: 1.3;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #5d5d5d;
    transition: all 0.5s ease;
  }
  #about .wrap .content .about_all .btn a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 11px;
    font-size: 0.8461538462rem;
    text-align: center;
    transition: all 0.5s ease;
  }
  #about .wrap .content .about_all .btn:hover {
    border: 1px solid #c00f57;
    color: #c00f57;
  }
  #about .wrap .content .about_all h2.nopage {
    margin: 200px auto 100px auto;
  }
}
/* about-tablet
------------------------------------------------------- */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  #about .wrap .content {
    padding: 100px 0 50px 0;
  }
}
/* service
------------------------------------------------------- */
#service .wrap .content {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 100px 0;
}
#service .wrap .content h2 {
  font-size: 26px;
  font-size: 2rem;
  font-family: adobe-garamond-pro, serif;
  margin: 100px auto;
}
#service .wrap .content .categoryArea {
  text-align: center;
  width: 90%;
  max-width: 900px;
  border-bottom: 1px solid #EEEEEE;
  margin: 0 auto 100px auto;
  padding: 0 0 100px 0;
}
#service .wrap .content .categoryArea h3 {
  position: relative;
  margin-bottom: 25px;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  font-size: 1.5384615385rem;
  line-height: 1.1;
}
#service .wrap .content .categoryArea h3::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  width: 20px;
  margin: 0 auto;
  border-top: 2px solid #c00f57;
  margin: 0 auto;
}
#service .wrap .content .categoryArea p {
  margin: 36px auto 30px auto;
  line-height: 2;
}
#service .wrap .content .categoryArea img {
  text-align: center;
  width: 90%;
  max-width: 900px;
  margin: 0 auto 30px auto;
  padding: 0;
}
#service .wrap .content .categoryArea .btn {
  margin: 0 auto;
}
#service .wrap .content .categoryArea .btn a {
  font-family: adobe-garamond-pro, serif;
}
#service .wrap .content .categoryArea2 {
  text-align: center;
  width: 90%;
  max-width: 900px;
  margin: 0 auto 100px auto;
  padding: 0 0;
}
#service .wrap .content .categoryArea2 h3 {
  position: relative;
  margin-bottom: 25px;
  font-family: adobe-garamond-pro, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  font-size: 1.5384615385rem;
  line-height: 1.1;
}
#service .wrap .content .categoryArea2 h3::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  width: 20px;
  margin: 0 auto;
  border-top: 2px solid #c00f57;
  margin: 0 auto;
}
#service .wrap .content .categoryArea2 p {
  margin: 36px auto 30px auto;
  line-height: 2;
}
#service .wrap .content .categoryArea2 img {
  text-align: center;
  width: 90%;
  max-width: 900px;
  margin: 0 auto 30px auto;
  padding: 0;
}
#service .wrap .content .categoryArea2 .btn {
  margin: 0 auto;
}
#service .wrap .content .categoryArea2 .btn a {
  font-family: adobe-garamond-pro, serif;
}

/* service-smp
------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  #service .wrap .content {
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-size: 0.9230769231rem;
    margin: 0 auto;
    padding: 30px 0 0 0;
  }
  #service .wrap .content h2 {
    font-size: 18px;
    font-size: 1.3846153846rem;
    font-family: adobe-garamond-pro, serif;
    margin: 0 auto 30px auto;
  }
  #service .wrap .content .categoryArea {
    text-align: center;
    width: 90%;
    margin: 0 auto 50px auto;
    padding: 0 0 50px 0;
  }
  #service .wrap .content .categoryArea h3 {
    font-size: 18px;
    font-size: 1.3846153846rem;
  }
  #service .wrap .content .categoryArea p {
    text-align: left;
    margin: 36px auto 20px auto;
  }
  #service .wrap .content .categoryArea img {
    width: 100%;
    max-width: 900px;
    margin: 0 auto 20px auto;
  }
  #service .wrap .content .categoryArea .btn {
    width: 100%;
    line-height: 1.3;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #5d5d5d;
    transition: all 0.5s ease;
  }
  #service .wrap .content .categoryArea .btn a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 11px;
    font-size: 0.8461538462rem;
    text-align: center;
    transition: all 0.5s ease;
  }
  #service .wrap .content .categoryArea .btn:hover {
    border: 1px solid #c00f57;
    color: #c00f57;
  }
  #service .wrap .content .categoryArea2 {
    text-align: center;
    width: 90%;
    max-width: 900px;
    margin: 0 auto 50px auto;
    padding: 0;
  }
  #service .wrap .content .categoryArea2 h3 {
    font-size: 18px;
    font-size: 1.3846153846rem;
  }
  #service .wrap .content .categoryArea2 p {
    text-align: left;
    margin: 36px auto 20px auto;
  }
  #service .wrap .content .categoryArea2 img {
    width: 100%;
    max-width: 900px;
    margin: 0 auto 20px auto;
  }
  #service .wrap .content .categoryArea2 .btn {
    width: 100%;
    line-height: 1.3;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #5d5d5d;
    transition: all 0.5s ease;
  }
  #service .wrap .content .categoryArea2 .btn a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 11px;
    font-size: 0.8461538462rem;
    text-align: center;
    transition: all 0.5s ease;
  }
  #service .wrap .content .categoryArea2 .btn:hover {
    border: 1px solid #c00f57;
    color: #c00f57;
  }
}
/* service-tablet
------------------------------------------------------- */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  #service .wrap .content {
    padding: 100px 0 0 0;
  }
}
/* privacy_policy
------------------------------------------------------- */
#privacy_policy .wrap .content {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 100px 0;
}
#privacy_policy .wrap .content h2 {
  font-size: 26px;
  font-size: 2rem;
  font-family: adobe-garamond-pro, serif;
  margin: 100px auto;
}
#privacy_policy .wrap .content .privacy_all {
  text-align: center;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0;
}
#privacy_policy .wrap .content .privacy_all p {
  text-align: left;
  margin-bottom: 60px;
}
#privacy_policy .wrap .content .privacy_all p.txt2 {
  text-align: left;
  margin-bottom: 20px;
}
#privacy_policy .wrap .content .privacy_all p.day {
  text-align: right;
  margin-bottom: 60px;
}
#privacy_policy .wrap .content .privacy_all h3 {
  text-align: left;
  font-size: 18px;
  font-size: 1.3846153846rem;
  margin-bottom: 20px;
}
#privacy_policy .wrap .content .privacy_all ul {
  text-align: left;
  margin: 0 0 50px 40px;
  padding: 0;
}
#privacy_policy .wrap .content .privacy_all ul li {
  list-style: disc;
  list-style-position: outside;
}

/* privacy_policy-smp
------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  #privacy_policy .wrap .content {
    text-align: center;
    font-size: 12px;
    font-size: 0.9230769231rem;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 0 0;
  }
  #privacy_policy .wrap .content h2 {
    font-size: 18px;
    font-size: 1.3846153846rem;
    font-family: adobe-garamond-pro, serif;
    margin: 0 auto 30px auto;
  }
  #privacy_policy .wrap .content .privacy_all {
    margin-bottom: 50px;
  }
  #privacy_policy .wrap .content .privacy_all p {
    margin-bottom: 30px;
  }
  #privacy_policy .wrap .content .privacy_all p.txt2 {
    margin-bottom: 10px;
  }
  #privacy_policy .wrap .content .privacy_all h3 {
    text-align: left;
    font-size: 16px;
    font-size: 1.2307692308rem;
    margin-bottom: 10px;
  }
  #privacy_policy .wrap .content .privacy_all ul {
    margin: 0 0 30px 20px;
  }
}
/* privacy_policy-tablet
------------------------------------------------------- */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  #privacy_policy .wrap .content {
    padding: 100px 0 0 0;
  }
}
/* contact
------------------------------------------------------- */
#contact .content,
#confirm .content,
#thanks .content {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 0 100px 0;
  text-align: center;
}
#contact .content h2,
#confirm .content h2,
#thanks .content h2 {
  margin: 100px auto;
  font-size: 26px;
  font-size: 2rem;
  font-family: adobe-garamond-pro, serif;
}
#contact .content p.capTxt,
#confirm .content p.capTxt,
#thanks .content p.capTxt {
  margin: 0 0 5px 0;
  padding: 0;
  text-align: right;
}
#contact .content p.capTxt span,
#confirm .content p.capTxt span,
#thanks .content p.capTxt span {
  color: #c42a65;
}
#contact .content ul,
#confirm .content ul,
#thanks .content ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto 50px auto;
  padding: 0;
  text-align: center;
  font-size: 13px;
  font-size: 1rem;
}
#contact .content ul li:nth-child(odd),
#confirm .content ul li:nth-child(odd),
#thanks .content ul li:nth-child(odd) {
  text-align: left;
  width: 25%;
  height: 50px;
  background-color: #e9e9e9;
  margin: 0 0 20px 0;
  padding: 15px 20px 0 20px;
  box-sizing: border-box;
}
#contact .content ul li:nth-child(odd) span,
#confirm .content ul li:nth-child(odd) span,
#thanks .content ul li:nth-child(odd) span {
  color: #c42a65;
}
#contact .content ul li.last,
#confirm .content ul li.last,
#thanks .content ul li.last {
  height: 106px;
}
#contact .content ul li:nth-child(even),
#confirm .content ul li:nth-child(even),
#thanks .content ul li:nth-child(even) {
  text-align: left;
  width: 75%;
  margin: 0 0 20px 0;
  padding: 0 0 0 30px;
  box-sizing: border-box;
}
#contact .content ul li:nth-child(even) input.box01,
#confirm .content ul li:nth-child(even) input.box01,
#thanks .content ul li:nth-child(even) input.box01 {
  width: 100%;
  height: 50px;
  border: 1px solid #DDDDDD;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
}
#contact .content ul li:nth-child(even) input.box02,
#confirm .content ul li:nth-child(even) input.box02,
#thanks .content ul li:nth-child(even) input.box02 {
  width: calc((100% - 27px) / 3);
  height: 50px;
  border: 1px solid #DDDDDD;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
}
#contact .content ul li:nth-child(even) textarea,
#confirm .content ul li:nth-child(even) textarea,
#thanks .content ul li:nth-child(even) textarea {
  width: 100%;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
}
#contact .content .sendBtn,
#confirm .content .sendBtn,
#thanks .content .sendBtn {
  width: 200px;
  height: 46px;
  line-height: 1.3;
  color: #5d5d5d;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #5d5d5d;
  transition: all 0.5s ease;
  cursor: pointer;
}
#contact .content .sendBtn a,
#confirm .content .sendBtn a,
#thanks .content .sendBtn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.5s ease;
}
#contact .content .sendBtn:hover,
#confirm .content .sendBtn:hover,
#thanks .content .sendBtn:hover {
  border: 1px solid #c00f57;
  color: #c00f57;
}
#contact .content .mb20,
#confirm .content .mb20,
#thanks .content .mb20 {
  margin-bottom: 20px;
}
#contact .content .mb100,
#confirm .content .mb100,
#thanks .content .mb100 {
  margin-bottom: 100px;
}

/* contact-smp
------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  #contact .content,
  #confirm .content,
  #thanks .content {
    text-align: center;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 60px 0 50px 0;
  }
  #contact .content h2,
  #confirm .content h2,
  #thanks .content h2 {
    font-size: 18px;
    font-size: 1.3846153846rem;
    font-family: adobe-garamond-pro, serif;
    margin: 0 auto 30px auto;
  }
  #contact .content p.capTxt,
  #confirm .content p.capTxt,
  #thanks .content p.capTxt {
    text-align: right;
    margin: 0 0 5px 0;
    padding: 0;
  }
  #contact .content p.capTxt span,
  #confirm .content p.capTxt span,
  #thanks .content p.capTxt span {
    color: #c42a65;
  }
  #contact .content ul,
  #confirm .content ul,
  #thanks .content ul {
    text-align: center;
    font-size: 13px;
    font-size: 1rem;
    margin: 0 auto 50px auto;
    padding: 0;
    display: block;
  }
  #contact .content ul li:nth-child(odd),
  #confirm .content ul li:nth-child(odd),
  #thanks .content ul li:nth-child(odd) {
    text-align: left;
    width: 100%;
    height: 50px;
    background-color: #e9e9e9;
    margin: 0 0 5px 0;
    padding: 15px 20px 0 20px;
    box-sizing: border-box;
  }
  #contact .content ul li:nth-child(odd) span,
  #confirm .content ul li:nth-child(odd) span,
  #thanks .content ul li:nth-child(odd) span {
    color: #c42a65;
  }
  #contact .content ul li.last,
  #confirm .content ul li.last,
  #thanks .content ul li.last {
    height: 106px;
  }
  #contact .content ul li:nth-child(even),
  #confirm .content ul li:nth-child(even),
  #thanks .content ul li:nth-child(even) {
    text-align: left;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0 0 0 0;
    box-sizing: border-box;
  }
  #contact .content ul li:nth-child(even) input.box01,
  #confirm .content ul li:nth-child(even) input.box01,
  #thanks .content ul li:nth-child(even) input.box01 {
    width: 100%;
    height: 50px;
    border: 1px solid #DDDDDD;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
  }
  #contact .content ul li:nth-child(even) input.box02,
  #confirm .content ul li:nth-child(even) input.box02,
  #thanks .content ul li:nth-child(even) input.box02 {
    width: calc((100% - 27px) / 3);
    height: 50px;
    border: 1px solid #DDDDDD;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
  }
  #contact .content ul li:nth-child(even) textarea,
  #confirm .content ul li:nth-child(even) textarea,
  #thanks .content ul li:nth-child(even) textarea {
    width: 100%;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
  }
  #contact .content .sendBtn,
  #confirm .content .sendBtn,
  #thanks .content .sendBtn {
    width: 100%;
    height: 46px;
    line-height: 1.3;
    color: #5d5d5d;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #5d5d5d;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  #contact .content .sendBtn a,
  #confirm .content .sendBtn a,
  #thanks .content .sendBtn a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.5s ease;
  }
  #contact .content .sendBtn:hover,
  #confirm .content .sendBtn:hover,
  #thanks .content .sendBtn:hover {
    border: 1px solid #c00f57;
    color: #c00f57;
  }
}
/* contact-tablet
------------------------------------------------------- */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  #contact .wrap .content,
  #confirm .wrap .content,
  #thanks .wrap .content {
    padding: 100px 0 50px 0;
  }
}