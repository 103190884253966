@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;

// フェードイン
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}



/* gallery
------------------------------------------------------- */
#gallery{
  
	.pagetop {
		position: fixed;
		right: 40px;
		bottom: 40px;
		z-index: 9999;
		width: 45px;
		height: 45px;
		margin: 0 auto 10px;
		padding-top: 19px;
		border: 1px solid $textColor;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		box-sizing: border-box;
		cursor: pointer;
	}
	.pagetop span {
		display: block;
		width: 15px;
		height: 15px;
		margin: 0 auto;
		border-top: 1px solid $textColor;
		border-right: 1px solid $textColor;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
  
  .wrap{
    .content{
			width: 100%;
      min-height: 990px;
			margin: 0 auto;
			padding: 60px 0 100px 0;
			text-align: center;
			
      h2{
        @include font-size(26);
        font-family: adobe-garamond-pro, serif;
        margin: 100px auto;
      }
		
			.gallery_all{
				display: flex;
				justify-content: space-between;
				width: 90%;
				max-width: 1200px;
				margin: 0 auto;
				padding: 0;
				text-align: center;
        
          .categoryBtn{
            display: none;
					}
				
				a{
					display: inline;
					color: $textColor;
					text-decoration: none;
					transition: all 0.5s ease;
					
					&:hover{
						color: $pointPink;
						text-decoration: none;
					}
				}
				.cr{
					color: $pointPink;
				}
				
				.left_column{
					position: relative;
					width: 25%;
					max-width: 260px;
					margin: 0;
					padding: 0;
					text-align: left;
					
					.categorywrap{
						position: absolute;
						top: 0;
						left: 0;
					}
					h3{
						margin: 0 auto 20px;
						@include font-size(15);
					}
					ul{
						text-align: left;
						margin: 0 0 20px;
						
						li{
							position: relative;
							margin: 0 0 10px 30px;
							line-height: 1.5;
							@include font-size(12);
							animation: fadeIn 1s ease-out 0s forwards;

							&::before{
								position: absolute;
								top: 0;
								left: -15px;
								content: "-　"
							}
						}
					}
					.chgShow{
						transition: all 0.5s ease;
						cursor: pointer;
					
						&:hover{
							color: $pointPink;
							text-decoration: none;
						}
						&.cr{
							a{
								color: $pointPink;
							}
						}
					}

					[data-category="private-resindece"],
					[data-category="speciality_made"],
					[data-category="styling"],
					.private-residence{
						display: none;
					}
				}
				
				.right_column{
					width: 70%;
					max-width: 900px;
					
					.g_entry{
						margin-bottom: 100px;

						h3{
							margin-bottom: 10px;
							text-align: left;
							@include font-size(22);
							opacity: 0;
							animation: fadeIn 5s ease-out 2s forwards;
						}
						p.category{
							margin-bottom: 10px;
							text-align: left;
							@include font-size(10);
							opacity: 0;
							animation: fadeIn 5s ease-out 2s forwards;
						}
						ul{
							display: flex;
							flex-wrap: wrap;
							// justify-content: space-between;
							width: 100%;
							margin: 0 auto;
							padding: 0;

							li{
								flex-grow: 0;
								width:calc((100% - 10px) / 3);
								margin: 0 5px 5px 0;
								
								figure{
									display: block;
								}
								&:nth-child(3n){
									margin-right: 0;
								}
								img{
									width: 100%;
									object-fit: cover;
                  font-family: 'object-fit: cover;'; /*IE対策*/
                   -ms-interpolation-mode: bicubic; /*IE対策*/
									opacity: 0;
								}
								.loaded{
									animation: fadeIn 5s ease-out 0s forwards;
								}
							}
							_:-ms-lang(x)::backdrop, li{
								width: 32.5%;
							}	/* Edge */
							*::-ms-backdrop, li{
								width: 32.5%;
							}	/* IE11 */
						}
						.caption{
							text-align: right;
							line-height: 1.5;
							@include font-size(10);
              transform: scale(0.8);
              transform-origin: right center;
							margin-right: 10px;
							opacity: 0;
							animation: fadeIn 5s ease-out 2s forwards;
						}
						.description{
							opacity: 0;
							animation: fadeIn 5s ease-out 2s forwards;

							p{
								text-align: left;
								@include font-size(13);
							}
						}
					}

					.showArea{
						display: none;

						&:first-child{
							display: block;
						}
					}
				}
			}

			.gallery_category{
				display: flex;
				justify-content: space-between;
				width: 60%;
				max-width: 1300px;
				margin: 0 auto;
		
				li{
					position: relative;
					width: calc((100% - 80px) / 2);
					max-width: 610px;

					h3{
						@include font-size(26);
						line-height: 1.1;
						font-family: adobe-garamond-pro, serif;

						&::after{
							display: block;
							width: 20px;
							height: 2px;
							margin: 20px auto;
							content: "";
							background: #c00f57;
						}
					}
					.description{
						margin-bottom: 30px;
					}

					a{
						&:hover{
							.category_photo{
								img{
									transform: scale(1.2);
								}
							}
						}
					}
					.category_photo{
						transition: all 0.5s ease;
						overflow: hidden;
					}
					.btn_gallery{
						width: 50%;
						max-width: 250px;
						margin: 30px auto;
						border: 1px solid #000;
						@include font-size(13);
						line-height: 1.3;
						font-family: adobe-garamond-pro, serif;

						a{
							padding: 12px;
						}
					}
				}
			}
	  }
  }

	.imgAll{
		display: flex;
		justify-content: center;
		align-items: center;
    height: 94vh;
    box-sizing: border-box;
    overflow: hidden;
	}	
  .imgArea{
		// display: none;
		width: auto;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		text-align: center;
		color: #AAAAAA;

	  img{
			width: auto;
			max-height: 60vh;
			margin-bottom: 10px;
	  }
	  .imgCap{
			@include font-size(10);
		  margin-bottom: 20px;
		  text-align: right;
		}
	}
	
	#cboxLoadedContent{
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		max-height: 100vh;

		.cboxPhoto{
			width: auto !important;
			max-width: 90%;
			height: auto !important;
			max-height: 80% !important;
			margin: auto  !important;
		}
	}
	#cboxTitle{
		float: none !important;
		right: 20px;
		left: auto;
		top: auto;
		bottom: 20px;
		@include font-size(10);
		text-align: right;
	}
	#cboxNext{
		right: 0;
	}
	#cboxPrevious{
		left: 0;
	}

	.full-image{
    figure{
      img{
        width: auto;
        max-width: 100%;
        max-height: 80vh;
        margin-bottom: 10px;
			}
      figcaption{
        .imgCap{
          position: absolute;
          right: 20px;
          bottom: 20px;
          text-align: right;
          @include font-size(10);
          margin-bottom: 10px;
        }
    
        .btn{
					display: none;
        }
      }
    }
  }
  #close-button{
    position:absolute;
    top:0;
    right:5px;
    z-index: 99999;
    display:block;
    width:40px;
    height:40px;
    margin:0;
    padding:0;
    border:0;
    background: url("../../img/close.png") no-repeat top center;
    outline:0;
    cursor:pointer;
    text-indent:-9999px;
    overflow:visible;

    svg{
      display: none;
    }
    &:hover{
      background-position:bottom center;
    }
  }
  #previous-button{
    position:absolute;
    top:0;
    left:0;
    background: url("../../img/ico_previous.png") no-repeat center left;
    background-size: 40px 60px;
    width:15%;
    height:100%;
    outline:none;
    text-indent:-9999px;

    svg{
      display: none;
    }
  }
  #next-button{
    position:absolute;
    top:0;
    right:0;
    background: url("../../img/ico_next.png") no-repeat center right;
    background-size: 40px 60px;
    width:15%;
    height:100%;
    outline:none;
    text-indent:-9999px;

    svg{
      display: none;
    }
  }
}

/* gallery-smp
------------------------------------------------------- */
@media only screen and (max-width:767px){
#gallery{
  
.pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  width: 45px;
  height: 45px;
  margin: 0 auto 10px;
  padding-top: 19px;
	border: 1px solid $textColor;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}
.pagetop span {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 auto;
  border-top: 1px solid $textColor;
  border-right: 1px solid $textColor;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
  
  .wrap{
    .content{
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding: 30px 0 0 0;
		
      h2{
        @include font-size(18);
        font-family: adobe-garamond-pro, serif;
        margin: 0 auto 30px auto;
      }
		
      .gallery_all{
        width: 94%;
        display: block;
						
        .left_column{
					position: static;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto 30px auto;
          padding: 0 0 10px 0;
          text-align: center;
          border-bottom: 1px solid #EEEEEE;

					.categorywrap{
						display: none;
						position: static;
					}
          h2{
            font-family: adobe-garamond-pro, serif;
            margin: 0 auto 30px auto;
            @include font-size(30);
          }
          .categoryBtn{
            display: block;
            position: relative;
            width: 100%;
            margin: 0 auto 20px auto;
            padding: 8px 0;
            border: 1px solid #EEEEEE;
            border-radius: 3px;
						text-align: center;
						cursor: pointer;

						span{
							position: absolute;
							top: 10px;
							right: 10px;
							display: block;
							width: 10px;
							height: 10px;
							margin: 0 auto;
							border-top: 1px solid #CFCFCF;
							border-right: 1px solid #CFCFCF;
							transform: rotate(135deg);
						}
					}
					h3{
            text-align: left;
						margin: 0 0 10px;
					}
					ul{
						margin: 0 0 10px;
						
						li{
							margin: 0 0 5px 30px;
							padding: 10px 0;

							&::before{
								top: 10px;
							}
						}
					}
				}
        
        .right_column{
          width: 100%;
          max-width: 900px;

					.g_entry{
						margin-bottom: 30px;
						padding-bottom: 30px;
						border-bottom: 1px solid #EEEEEE;
						
						h3{
							margin-bottom: 5px;
						}

							ul{
								margin-bottom: 5px;
								
								li{
								width:49.5%;
								margin: 0 1% 1% 0;

								&:nth-child(3n){
									margin-right: 1%;
								}
								&:nth-child(2n){
									margin-right: 0;
								}
							}
						}
						.caption{
							margin-bottom: 5px;
						}
					}
				}
			}

			.gallery_category{
				display: block;
				width: 90%;
				margin: 0 auto;
		
				li{
					width: 100%;

					.btn_gallery{
						width: 90%;
					}
				}
			}

		}
		
	}

		#cboxWrapper{
			display: flex !important;
			align-items: center;
			justify-content: center;
			width: 100vw !important;
			height: 85vh !important;
			overflow: hidden !important;
		}
		#cboxLoadedContent{
			display: flex;
			align-items: center;
			width: 100% !important;
			height: 100% !important;
			max-height: 100vh;
	
			.cboxPhoto{
				width: auto !important;
				max-width: 100%;
				height: auto !important;
				max-height: 90% !important;
				margin: auto  !important;
			}
		}
		#colorbox{
			position: relative;
			top: 0 !important;
		}
		#cboxContent{
			position: static;
		}
		#cboxTitle{
			bottom: 5px;
		}
		#cboxClose{
			top: 0;
			right: 5px;
		}
	#cboxNext{
		right: -20px;
	}
	#cboxPrevious{
		left: -20px;
	}
  

  
	}
}

/* gallery-tablet
------------------------------------------------------- */
/* top-tablet
------------------------------------------------------- */
@media screen and (min-width:768px) and ( max-width:960px) {
	#gallery{
		.wrap{
			.content{
				.gallery_category{
					li{
						.btn_gallery{
							width: 90%;
						}
					}
				}
			}
		}
		
		#cboxNext{
			right: -20px;
		}
		#cboxPrevious{
			left: -20px;
		}
  }	
}
@media screen and (min-width:961px) and ( max-width:1024px) {
	#gallery{
    #previous-button,
    #next-button{
      display: block !important;
    }
  }
}
