@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;



/* contact
------------------------------------------------------- */
#contact,
#confirm,
#thanks{
	  
    .content{
      width: 90%;
      max-width: 800px;
      margin: 0 auto;
      padding: 60px 0 100px 0;
      text-align: center;
      
      h2{
        margin: 100px auto;
        @include font-size(26);
        font-family: adobe-garamond-pro, serif;
      }
      
      p.capTxt{
        margin: 0 0 5px 0;
        padding: 0;
        text-align: right;
        
        span{
          color: #c42a65;
        }
      }
      
			ul{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin: 0 auto 50px auto;
				padding: 0;
				text-align: center;
				@include font-size(13);
				
				li:nth-child(odd){
					text-align: left;
					width: 25%;
          height: 50px;
					background-color: #e9e9e9;
					margin: 0 0 20px 0;
					padding: 15px 20px 0 20px;
					box-sizing: border-box;
          
          span{
            color: #c42a65;
          }
				}
        li.last{
          height: 106px;
        }
				li:nth-child(even){
					text-align: left;
					width: 75%;
					margin: 0 0 20px 0;
					padding: 0 0 0 30px;
					box-sizing: border-box;
          
          input.box01{
            width: 100%;
            height: 50px;
            border: 1px solid #DDDDDD;
            margin: 0;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 3px;
          }
          input.box02{
            width: calc((100% - 27px) / 3);
            height: 50px;
            border: 1px solid #DDDDDD;
            margin: 0;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 3px;
          }
          textarea{
            width: 100%;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;
          }
				}
			}
      
		  .sendBtn{
        width: 200px;
        height: 46px;
        line-height: 1.3;
        color: $textColor;
        margin: 0 auto;
        background: #fff;
        border: 1px solid $textColor;
        transition: all 0.5s ease;
        cursor: pointer;

        a{
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px 0;
          box-sizing: border-box;
          text-align: center;
          transition: all 0.5s ease;
        }
        &:hover{
          border: 1px solid $pointPink;
          color: $pointPink;
        }
      }
      
      .mb20{
        margin-bottom: 20px;
      }
      .mb100{
        margin-bottom: 100px;
      }
			
		}
}
  

/* contact-smp
------------------------------------------------------- */
@media only screen and (max-width:767px){
  #contact,
  #confirm,
  #thanks{
    .content{
      text-align: center;
      width: 90%;
      max-width: 800px;
      margin: 0 auto;
      padding: 60px 0 50px 0;
      
      h2{
        @include font-size(18);
        font-family: adobe-garamond-pro, serif;
        margin: 0 auto 30px auto;
      }
      
      p.capTxt{
        text-align: right;
        margin: 0 0 5px 0;
        padding: 0;
        
        span{
          color: #c42a65;
        }
      }
      
			ul{
				text-align: center;
				@include font-size(13);
				margin: 0 auto 50px auto;
				padding: 0;
				display: block;
				
				li:nth-child(odd){
					text-align: left;
					width: 100%;
            height: 50px;
					background-color: #e9e9e9;
					margin: 0 0 5px 0;
					padding: 15px 20px 0 20px;
					box-sizing: border-box;
          
          span{
            color: #c42a65;
          }
				}
        li.last{
          height: 106px;
        }
				li:nth-child(even){
					text-align: left;
					width: 100%;
					margin: 0 0 20px 0;
					padding: 0 0 0 0;
					box-sizing: border-box;
          
          input.box01{
            width: 100%;
            height: 50px;
            border: 1px solid #DDDDDD;
            margin: 0;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 3px;
          }
          input.box02{
            width: calc((100% - 27px) / 3);
            height: 50px;
            border: 1px solid #DDDDDD;
            margin: 0;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 3px;
          }
          textarea{
            width: 100%;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;
          }
				}
			}
      
		  .sendBtn{
        width: 100%;
        height: 46px;
        line-height: 1.3;
        color: $textColor;
        margin: 0 auto;
        background: #fff;
        border: 1px solid $textColor;
        transition: all 0.5s ease;
        cursor: pointer;

        a{
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px 0;
          box-sizing: border-box;
          text-align: center;
          transition: all 0.5s ease;
        }
        &:hover{
          border: 1px solid $pointPink;
          color: $pointPink;
        }
      }
    }
  
  }
}

/* contact-tablet
------------------------------------------------------- */
@media screen and (min-width:481px) and ( max-width:1024px) {
  #contact,
  #confirm,
  #thanks{
  .wrap{

    .content{
      padding: 100px 0 50px 0;
    }
	  
	  
	}
  }	
}
