@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;



/* top
------------------------------------------------------- */
#top{
  .wrap{
    .content{
      position: relative;
      width: 100%;
      margin: 0 auto;
      background: url('../../img/bg.jpg') center top fixed;
      background-size: cover;

      .main{
        position: relative;
        width: 100%;
        height: 100vh;
        background: url("../../img/bg_main.jpg") top left fixed;
        background-size: cover;
        box-sizing: border-box;
        text-align: center;
        
        .logoArea{
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          
          h2{
            @include font-size(70);
            color: #8b8b9c;
          }
          .sub{
            margin-top: 30px;
            font-family: adobe-garamond-pro, serif;
            @include font-size(20);
            color: $pointPink;
          }
        }
        .news{
          position: absolute;
          right: 0;
          bottom: 30px;
          width: auto;
          max-width: 94%;
          padding: 5px 20px;
          background: rgba(255,255,255,.85);
          box-sizing: border-box;
          text-align: left;
        }
        .topCap{
          position: absolute;
          right: 20px;
          bottom: 5px;
          padding: 0;
          @include font-size(10);
          text-align: right;
          color: $textColor;
        }
      }

      .bg{
        position: relative;
        width: 100%;
        height: 1000px;
        background: url('../../img/main01.jpg') no-repeat center bottom fixed;
        background-size: cover;
        
        .bgCap{
          position: absolute;
          right: 20px;
          bottom: 5px;
          padding: 0;
          @include font-size(10);
          text-align: right;
          color: #CCCCCC;
        }
        
          .bgImg{
            display: none;
          }
      }

      .concept{
        padding: 100px 0;
        // background: url('../../img/bg_concept.jpg') no-repeat center top;
        // background-size: 100% auto;
        text-align: center;

        h2{
    			margin-bottom: 20px;
          @include font-size(20);
          font-family: adobe-garamond-pro, serif;
        }
        p{
          // width: 640px;
          margin: 30px auto;

          strong{
            display: block;
            margin-bottom: 40px;
            @include font-size(18);
          }
        }
        h3{
          margin: 40px auto;
          @include font-size(18);
        }
        .langChg{
          display: block;
          position: relative;
          width: 60px;
          margin: 0 auto 50px;
          padding-left: 20px;
          border: none;
          background: transparent; 
          color: $textColor;
          cursor: pointer;
          transition: all 0.5s ease;
                
          &:hover{
            color: $pointPink;
          }
          &::before{
            position: absolute;
            left: 0;
            bottom: 8px;
            width: 15px;
            height: 5px;
            content: "";
            border-bottom: 1px solid #343434;
          }
          &::after{
            position: absolute;
            left: -1px;
            bottom: 2px;
            width: 15px;
            height: 9px;
            content: "";
            border-right: 1px solid #343434;
            transform: rotate(-45deg);
          }
        }
        .toggleArea{			  
          h3{
            @include font-size(19);
            margin: 0 auto;
          }
          p{
            @include font-size(14);
          }
          p.last{
            margin: 30px auto 80px;
          }
          .newline{
            display: block;
          }
        }
        .btn{
          margin: 0 auto;
        }
      }

      .service{
        width: 100%;
        padding: 100px 0;
        background: #ffffff;

        .service-wrap{
          display: flex;
          // justify-content: space-between;
          width: 80%;
          max-width: 1200px;
          margin: 0 auto 50px;

          .service-box{
            width: calc((100% - 10px)/3);
            // width: 385px;
            padding: 0;
            text-align: center;

            &:last-child{
              margin-right: 0;
            }
            h3{
		      		position: relative;
              margin-bottom: 25px;
              font-family: adobe-garamond-pro, serif;
              font-style: normal;
              font-weight: 400;
              @include font-size(26);
		      		line-height: 1.1;
				
              &::after{
                content: "";
                position: absolute;
                bottom: -20px;
                left: 0;
                right: 0;
                width: 20px;
                margin: 0 auto;
                border-top: 2px solid $pointPink;
                margin: 0 auto;
              }
            }
            p{
              margin-top: 36px;
            }
          }
        }
      }

      .gallery{
        padding: 0 0 100px 0;
        background: transparent;
        background: url("../../img/bg.jpg") no-repeat center bottom;
        background-size: cover;

        .gallery-wrap{
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          margin-bottom: 50px;
          padding: 5px 5px 0 5px;
    			background-color: #FFFFFF;
          box-sizing: border-box;

          p{
            width: calc((100% - 20px)/3);
            margin: 0 5px 5px 0;

            &:nth-child(3n){
              margin-right: 0;
            }
          }
        }
      }

      .btn{
        width: 200px;
        margin: 0 auto;
        background: #fff;
        border: 1px solid $textColor;
		    transition: all 0.5s ease;
        
        a{
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px 0;
          box-sizing: border-box;
          @include font-size(16);
          font-family: adobe-garamond-pro, serif;
          font-style: normal;
          font-weight: 400;
          text-align: center;
		    	transition: all 0.5s ease;
        }
        &:hover{
          border: 1px solid $pointPink;
          color: $pointPink;
        }
      }
		
    }
  }

	.imgAll{
    position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
    width: 100%;
		height: 100%;
		box-sizing: border-box;
		overflow: hidden;
	}
  
  .full-image{
    figure{
      img{
        width: auto;
        max-width: 100%;
        max-height: 80vh;
        margin-bottom: 10px;
        }
      figcaption{
        .imgCap{
          position: absolute;
          right: 20px;
          bottom: 20px;
          text-align: right;
          @include font-size(10);
          margin-bottom: 10px;
        }
    
        .btn{
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 999999;
          width: 100px;
          margin: 0 auto;
          background: transparent; 
          border: none;
          transition: all 0.5s ease;
          
          a{
            display: block;
            width: 100%;
            height: 100%;
            padding: 8px 0;
            box-sizing: border-box;
            @include font-size(12);
            font-family: adobe-garamond-pro, serif;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            color: #AAAAAA;
            transition: all 0.5s ease;
            
              &:hover{
                color: $pointPink;
              }
              &:active{
                color: $pointPink;
              }
              &::before{
                position: absolute;
                left: 0;
                bottom: 16px;
                width: 15px;
                height: 5px;
                content: "";
                border-bottom: 1px solid #AAAAAA;
              }
              &::after{
                position: absolute;
                left: -1px;
                bottom: 10px;
                width: 15px;
                height: 9px;
                content: "";
                border-right: 1px solid #AAAAAA;
                transform: rotate(-45deg);
              }
          }
        }
      }
    }
  }
  #close-button{
    position:absolute;
    top:0;
    right:5px;
    z-index: 99999;
    display:block;
    width:40px;
    height:40px;
    margin:0;
    padding:0;
    border:0;
    background: url("../../img/close.png") no-repeat top center;
    outline:0;
    cursor:pointer;
    text-indent:-9999px;
    overflow:visible;

    svg{
      display: none;
    }
    &:hover{
      background-position:bottom center;
    }
  }
  #previous-button{
    position:absolute;
    top:0;
    left:0;
    background: url("../../img/ico_previous.png") no-repeat center left;
    background-size: 40px 60px;
    width:15%;
    height:94%;
    outline:none;
    text-indent:-9999px;

    svg{
      display: none;
    }
  }
  #next-button{
    position:absolute;
    top:0;
    right:0;
    background: url("../../img/ico_next.png") no-repeat center right;
    background-size: 40px 60px;
    width:15%;
    height:94%;
    outline:none;
    text-indent:-9999px;

    svg{
      display: none;
    }
  }


  .imgArea{
    // display: none;
    text-align: center;
    width: auto;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #AAAAAA;
	  
	  img{
      width: auto;
      max-width: 100%;
      max-height: 80vh;
		  margin-bottom: 10px;
	  }
	  .imgCap{
      position: absolute;
      right: 20px;
      bottom: 20px;
		  text-align: right;
		  @include font-size(10);
		  margin-bottom: 10px;
	  }
    .btn{
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 999999;
      width: 100px;
      margin: 0 auto;
      background: transparent; 
      border: none;
      transition: all 0.5s ease;
      
      a{
        display: block;
        width: 100%;
        height: 100%;
        padding: 8px 0;
        box-sizing: border-box;
        @include font-size(12);
        font-family: adobe-garamond-pro, serif;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        color: #AAAAAA;
        transition: all 0.5s ease;
        
          &:hover{
            color: $pointPink;
          }
          &:active{
            color: $pointPink;
          }
          &::before{
            position: absolute;
            left: 0;
            bottom: 16px;
            width: 15px;
            height: 5px;
            content: "";
            border-bottom: 1px solid #AAAAAA;
          }
          &::after{
            position: absolute;
            left: -1px;
            bottom: 10px;
            width: 15px;
            height: 9px;
            content: "";
            border-right: 1px solid #AAAAAA;
            transform: rotate(-45deg);
          }
      }
    }
  }
	
}
  

/* top-smp
------------------------------------------------------- */
@media only screen and (max-width:767px){
	#top{
    .wrap{
      .content{
        width: 100%;
		  @include font-size(12);
        margin: 0 auto;
  
      .main{
        position: relative;
        width: 100%;
        height: 67vh;
        background: url("../../img/bg_main.jpg") no-repeat left top;
        background-size: 100%;
        box-sizing: border-box;
        text-align: center;
                
        .logoArea{
          position: absolute;
          top: 60%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          
          h2{
            @include font-size(40);
          }
          .sub{
            margin-top: 10px;
            @include font-size(10);
          }
        }
        .news{
          position: absolute;
          right: 0;
          bottom: 30px;
          width: auto;
          padding: 5px 15px;
          background: rgba(255,255,255,.85);
          box-sizing: border-box;
          text-align: left;
        }
        .topCap{
          position: absolute;
          right: 20px;
          bottom: 5px;
          padding: 0;
          @include font-size(10);
          text-align: right;
          color: $textColor;
        }
      }  

        .bg{
          width: 100%;
          height: auto;
          
          .bgImg{
            display: block;
          }
        }
  
        .concept{
          position: relative;
          padding: 80px 0 80px 0;
    			background-image: none;
          background-color: rgba(255,255,255,1);
          
          // &::after{
            // position: fixed;
            // left: 0;
            // top: 0;
            // z-index: -1;
            // display: block;
            // width: 100%;
            // height: 100%;
            // content: "";
            // background: url('../../img/bg-smp.jpg') no-repeat left bottom;
            // background-size: 228% auto;
          // }
          
          h2{
            margin-bottom: 70px;
          }
          
          p{
            width: 90%;
            margin: 0 auto 20px auto;
            text-align: left;
			  
            strong{
              text-align: center;
            }
          }
          .langChg{
            width: 57px;
            margin: 0 auto 50px;
          
          &:hover{
            color: $textColor;
          }
          &:active{
            color: $pointPink;
          }

            
          }
          span.smp{
            display: inline-block !important;
          }
          .toggleArea{
            h3{
              text-align: left;
              width: 90%;
              margin: 0 auto 20px auto;
            }
            p.last{
              margin-bottom: 50px;
            }
          .newline{
            display: none;
          }
          }

          .btn{
            width: 90%;
          }
			
        }

        .service{
          padding: 0 0 80px 0;
  
          .service-wrap{
            display: block;
            width: 100%;
            margin-bottom: 50px;
  
            .service-box{
              width: 90%;
              margin: 0 auto 20px;
              padding: 0 0 20px;
			      	border-bottom: 1px solid #F3F3F3F3;
  
              &:last-child{
                margin-right: auto;
              }
              h3{
                margin-bottom: 25px;
                font-family: adobe-garamond-pro, serif;
                font-style: normal;
                font-weight: 400;
                @include font-size(26);
              }
              p{
              }
            }
          }
          .btn{
            width: 90%;
          }
        }
  
        .gallery{
          // -webkit-background-color: rgba(255,255,255,0);
          background: url('../../img/bg.jpg') no-repeat left bottom scroll;
          background-size: 100% auto;
          padding-bottom: 80px;

          .gallery-wrap{
            margin-bottom: 50px;
            
            p{
              width: 100%;
              margin: 0 0 5px;
            }
          }
          .btn{
            width: 93%;
          }
        }
		  
      }
    }

    .imgArea{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100vh; /* 変数をサポートしていないブラウザのフォールバック */
      height: calc(var(--vh, 1vh) * 100);
      max-height: 100%;
      
      img{
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 60vh;
        margin-bottom: 0;
      }

      .imgCap{
        bottom: 40px;
      }
      .btn{
        right: 0;
        bottom: 20px;
        z-index: 999999;
      }
    }
    #cboxNext{
      right: -20px;
    }
    #cboxPrevious{
      left: -20px;
    }

    .full-image{
      figure{
        img{
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 60vh;
          margin-bottom: 0;
        }
        figcaption{
          .imgCap{
            bottom: 40px;
          }
      
          .btn{
            right: 0;
            bottom: 20px;
            z-index: 999999;    
          }
        }
      }
    }

	}
}

/* top-tablet
------------------------------------------------------- */
@media screen and (min-width:768px) and ( max-width:1024px) {
	#top{
    .wrap{
      .content{
        background: transparent;

        .main{
          position: relative;
          width: 100%;
          height: 75vh;
          background: url("../../img/bg_main.jpg") top left fixed;
          background-size: 100% auto;
          box-sizing: border-box;
          text-align: center;
        }
        .bg{
          position: relative;
          width: 100%;
          height: 70vh;
          background: url('../../img/main01.jpg') no-repeat center bottom scroll;
          background-size: cover;
        
          .bgImg{
            display: none;
          }
      }
        
      .concept{
        padding: 70px 0 80px 0;
          h2{
            margin-bottom: 65px;
          }
        .langChg{
          &:hover{
            color: $textColor;
          }
          &:active{
            color: $pointPink;
          }
        }
          .toggleArea{
            p.last{
              margin-bottom: 50px;
            }
        }
      }
        
        .service{
          padding: 0 0 80px 0;
          .service-wrap{
            width: 94%;
            .service-box{
              margin: 0 auto;
              padding: 0 0;
            }
          }
        }
        
        .gallery{
          background-size: 100% auto;
          padding-bottom: 80px;
        }
        
        
        
      }
    }

    .imgArea{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-height: 100%;
      
      img{
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
      .imgCap{
        bottom: 40px;
      }
      .btn{
        right: 0;
        bottom: 20px;
        z-index: 999999;
      }
    }
    #cboxNext{
      right: -20px;
      height: 92%;
    }
    #cboxPrevious{
      left: -20px;
      height: 92%;
    }

    .full-image{
      figure{
        img{
          max-width: 100%;
          height: auto;
          margin-bottom: 10px;
          }
        figcaption{
          .imgCap{
            bottom: 40px;
          }
      
          .btn{
            right: 0;
            bottom: 20px;
            z-index: 999999;    
          }
        }
      }
    }
	}
}

@media screen and (min-width:961px) and ( max-width:1024px) {
  #top{
    #previous-button,
    #next-button{
      display: block !important;
    }
  }
}
