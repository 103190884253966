@charset "UTF-8";
@use 'sass:math';

@mixin font-size($size, $viewport: false) {
  @if $viewport {
    font-size: get_vw($size, $viewport);
  } @else {
    font-size: $size + px;
    font-size: math.div($size , 13) + rem;
  }
}

// color設定
$baseColor  : #222;
$pointPink  : #c00f57;
$textColor	: #5d5d5d;



/* service
------------------------------------------------------- */
#service{
  .wrap{

    .content{
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding: 60px 0 100px 0;

      h2{
        @include font-size(26);
        font-family: adobe-garamond-pro, serif;
        margin: 100px auto;
      }
		
    .categoryArea{
      text-align: center;
      width: 90%;
      max-width: 900px;
      border-bottom: 1px solid #EEEEEE;
      margin: 0 auto 100px auto;
      padding: 0 0 100px 0;
			
      h3{
        position: relative;
        margin-bottom: 25px;
        font-family: adobe-garamond-pro, serif;
        font-style: normal;
        font-weight: 400;
        @include font-size(20);
        line-height: 1.1;

        &::after{
          content: "";
          position: absolute;
          bottom: -20px;
          left: 0;
          right: 0;
          width: 20px;
          margin: 0 auto;
          border-top: 2px solid $pointPink;
          margin: 0 auto;
        }
      }
      p{
        margin: 36px auto 30px auto;
        line-height: 2;
      }
      img{
        text-align: center;
        width: 90%;
        max-width: 900px;
        margin: 0 auto 30px auto;
        padding: 0;
      }
      .btn{
        margin: 0 auto;

        a{
          font-family: adobe-garamond-pro, serif;
        }
      }
    }
		
  .categoryArea2{
    text-align: center;
    width: 90%;
    max-width: 900px;
    margin: 0 auto 100px auto;
    padding: 0 0;
			
    h3{
      position: relative;
      margin-bottom: 25px;
      font-family: adobe-garamond-pro, serif;
      font-style: normal;
      font-weight: 400;
      @include font-size(20);
      line-height: 1.1;

      &::after{
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        width: 20px;
        margin: 0 auto;
        border-top: 2px solid $pointPink;
        margin: 0 auto;
      }
    }
    p{
      margin: 36px auto 30px auto;
      line-height: 2;
    }
    img{
      text-align: center;
      width: 90%;
      max-width: 900px;
      margin: 0 auto 30px auto;
      padding: 0;
    }
    .btn{
      margin: 0 auto;

      a{
        font-family: adobe-garamond-pro, serif;
      }
    }
  }
      
      
      
		}
	}
}
  

/* service-smp
------------------------------------------------------- */
@media only screen and (max-width:767px){
#service{
  .wrap{
	  
    .content{
      text-align: center;
      width: 100%;
      @include font-size(12);
      margin: 0 auto;
      padding: 30px 0 0 0;
		
      h2{
        @include font-size(18);
        font-family: adobe-garamond-pro, serif;
        margin: 0 auto 30px auto;
      }
	  
      .categoryArea{
        text-align: center;
        width: 90%;
        margin: 0 auto 50px auto;
        padding: 0 0 50px 0;
      
        h3{
          @include font-size(18);
        }
        p{
          text-align: left;
          margin: 36px auto 20px auto;
        }
        img{
          width: 100%;
          max-width: 900px;
          margin: 0 auto 20px auto;
        }
        .btn{
          width: 100%;
          line-height: 1.3;
          margin: 0 auto;
          background: #fff;
          border: 1px solid $textColor;
          transition: all 0.5s ease;

          a{
            display: block;
            width: 100%;
            height: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            @include font-size(11);
            text-align: center;
            transition: all 0.5s ease;
          }
          &:hover{
            border: 1px solid $pointPink;
            color: $pointPink;
          }
        }
      }
		
      .categoryArea2{
        text-align: center;
        width: 90%;
        max-width: 900px;
        margin: 0 auto 50px auto;
        padding: 0;
      
        h3{
          @include font-size(18);
        }
        p{
          text-align: left;
          margin: 36px auto 20px auto;
        }
        img{
          width: 100%;
          max-width: 900px;
          margin: 0 auto 20px auto;
        }
        .btn{
          width: 100%;
          line-height: 1.3;
          margin: 0 auto;
          background: #fff;
          border: 1px solid $textColor;
          transition: all 0.5s ease;

          a{
            display: block;
            width: 100%;
            height: 100%;
            padding: 10px 0;
            box-sizing: border-box;
            @include font-size(11);
            text-align: center;
            transition: all 0.5s ease;
          }
          &:hover{
            border: 1px solid $pointPink;
            color: $pointPink;
          }
        }
      }
    }
    
    
    
    }
  }	
}

/* service-tablet
------------------------------------------------------- */
@media screen and (min-width:481px) and ( max-width:1024px) {
#service{
  .wrap{

    .content{
      padding: 100px 0 0 0;
    }
	  
	  
	}
  }	
}

